import { Grid, ImageListItem, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import LogoSantaFara80Img from '../img/logoSantaFara80.png';

const HomepageCarouselItem = ({img,withLogo}) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (<>{isLargeScreen ?
            <Paper style={{
                paddingLeft: "100px", 
                paddingBottom: "50px", 
                backgroundImage: (`url(${img})`), 
                backgroundSize: "cover", 
                backgroundPosition: "center"
            }}>
                {withLogo ? <img style={{height: "250px"}} src={LogoSantaFara80Img} alt="Logo 80° anniversario" /> : <div style={{height: "254px"}} /> }
                <Typography variant="h1" fontWeight="bold" color="white" textAlign="left" marginTop="0px">Basilica<br/>Parrocchia Santa Fara - Bari</Typography>
                <Typography variant="h3" color="white" textAlign="left">Qui seminant in lacrymis in exultatione metent. Salmo CXXV, v. 5</Typography>
            </Paper> : <Paper style={{height: "270px", paddingLeft: "40px", backgroundImage: (`url(${img})`), backgroundSize: "cover", backgroundPosition: "center" }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h2" fontWeight="bold" color="white" textAlign="left" paddingTop="40px">Basilica<br/>Parrocchia<br/>Santa Fara<br/>Bari</Typography>
                    </Grid>
                    {withLogo ? <Grid item xs={6}>
                        <ImageListItem>
                            <img src={LogoSantaFara80Img} alt="Logo 80° anniversario" />
                        </ImageListItem>
                    </Grid> : null}
                </Grid>
            </Paper>
        }</>
    );
}

export default HomepageCarouselItem;