import { useContext } from "react";
import BasicModal from "./BasicModal";
import { GlobalContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

const SessionExpiredModal = () => {
    const {
        openSessionExpiredModal,
        setOpenSessionExpiredModal
    } = useContext(GlobalContext);
    const navigate = useNavigate();

    return (<BasicModal 
        open={openSessionExpiredModal} 
        setOpen={() => {
            navigate("/login");
            setOpenSessionExpiredModal(false);
        }} 
        title="Sessione scaduta" 
        description="E' necessario effettuare nuovamente il login." 
    />);
}

export default SessionExpiredModal;