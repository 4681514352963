import { Button, Grid, ImageListItem, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfirmModal from "../components/ConfirmModal";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DeleteForever } from "@mui/icons-material";
import useNoticeActions from "../services/useNoticeActions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AuthNoticePage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const  { _id } = useParams();
    const navigate = useNavigate();
    const noticeActions = useNoticeActions();
    const [imageName, setImageName] = useState("");
    const [imagePreview, setImagePreview] = useState();
    const [imageError, setImageError] = useState("");
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];

    useEffect(() => {
        if(_id) {
            populateForm();
        }
    }, [])

    const populateForm = async () => {
        let article = await noticeActions.retrieveNotice(_id);
        let imageUrl = article[0]?.image;
        let file = await noticeActions.retrieveImage(imageUrl);
        reset({
            title: article[0]?.title,
            description: article[0]?.description,
            text: article[0]?.text,
            image: file
        });
        setImageError("");
        setImageName(imageUrl.substring(imageUrl.lastIndexOf("/")+1));
        setImagePreview(URL.createObjectURL(file));
    }

    const initialValues = {
        title: "",
        description: "",
        text: ""
    }

    const validationSchema = Yup.object({
        title: Yup.string().required("obbligatorio"),
        description: Yup.string().required("obbligatorio"),
        text: Yup.string().required("obbligatorio")
    }).test((value) => value.image !== null && value.image !== undefined)

    const { handleSubmit, control, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let res = reader.result;
            resolve(res.substring(res.indexOf(",")+1));
        }
        reader.onerror = reject;
    });

    const onSubmit = async (values) => {
        let payload = {
            title: values.title,
            description: values.description,
            text: values.text,
            image_name: imageName,
            image_content: await toBase64(values.image)
        };
        if(_id) {
            payload.id = _id
        }
        await noticeActions.postArticle(payload);
        navigate("/admin");
    }

    const deleteArticle = async () => {
        await noticeActions.deleteArticle(_id);
        navigate("/admin");
    }

    return(<>
        <Grid container paddingLeft={isLargeScreen ? 8 : 3} paddingRight={isLargeScreen ? 8 : 3} paddingTop={4} paddingBottom={4} spacing={3}>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
                <Typography variant="h2" fontWeight="bold">Area di amministrazione</Typography>
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={0} md={3} />
            <Grid item xs={8} md={5}>
                <Typography variant="h4" fontWeight="bold" textAlign="left">{_id ? "Modifica articolo" : "Crea articolo" }</Typography>
            </Grid>
            <Grid item xs={3} md={1}>
                { _id ? <Button fullWidth color="primary" onClick={() => { setOpenDeleteModal(true) }}><DeleteForever /></Button> : null }
            </Grid>
            <Grid item xs={0} md={3} />
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6} >
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="title"
                                render={({ field, fieldState }) => (
                                    <TextField
                                        fullWidth
                                        label="Titolo"
                                        multiline
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="description"
                                render={({ field, fieldState }) => (
                                    <TextField
                                        fullWidth
                                        label="Descrizione"
                                        multiline
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="text"
                                render={({ field, fieldState }) => (
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={{
                                            toolbar: ["undo","redo","|","heading","|","bold","italic","|","link","blockQuote","|","bulletedList","numberedList","outdent","indent"]
                                        }}
                                        data={field.value}
                                        onChange={ (event,editor) => { field.onChange(editor.getData()); }}
                                    />
                                )} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                control={control}
                                name="image"
                                render={({ field, fieldState }) => (
                                    <Button 
                                        fullWidth
                                        component="label"
                                        startIcon={<CloudUploadIcon />}>
                                        Upload file
                                        <input
                                            hidden
                                            type="file"
                                            value={field.value?.fileName}
                                            onChange={(event) => { 
                                                let file = event.target.files[0];
                                                if(ALLOWED_IMAGE_TYPES.includes(file.type)) {
                                                    setImageError("");
                                                    setImageName(file.name);
                                                    setImagePreview(URL.createObjectURL(file));
                                                    field.onChange(file); 
                                                }else {
                                                    setImageError("Tipo di file non ammesso");
                                                }
                                            }}
                                        />
                                    </Button>
                                )} />
                            <Typography color="error" textAlign="left">{imageError}</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {imagePreview ? <ImageListItem>
                                <img src={imagePreview} alt={imageName}/>
                            </ImageListItem> : null}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button fullWidth variant="outlined" onClick={() => { setOpenCancelModal(true) }}>Cancella</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button fullWidth variant="contained" type="submit">Carica</Button>
                        </Grid>
                    </Grid>   
                </form>
            </Grid>
            <Grid item xs={12} md={3} />
        </Grid>
        <ConfirmModal 
            open={openCancelModal} 
            setOpen={setOpenCancelModal} 
            title="Conferma cancellazione modifiche" 
            description="Confermando tutte le modifiche apportate andranno perse."
            confirmFn={() => { navigate("/admin") }}
        />
        <ConfirmModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            title="Conferma eliminazione articolo"
            description="Confermando l'articolo verrà eliminato definitivamente."
            confirmFn={deleteArticle}
        />
    </>);
}

export default AuthNoticePage;