import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const BasicModal = ({open, setOpen, title, description}) => {

    return (
        <Dialog open={open}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {description}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => {setOpen(false)}}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default BasicModal;