import { Grid, ImageListItem, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import NoticeHeaderImg from '../img/noticeHeader.jpg';
import { useEffect, useState } from "react";
import useNoticeActions from "../services/useNoticeActions";
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet,  } from "react-helmet";

const NoticeSinglePage = () => {
    const { _id } = useParams();
    const [notice, setNotice] = useState([]);
    const [startLoadData, setStartLoadData] = useState(false);
    const noticeActions = useNoticeActions();
    const theme = useTheme();
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const fetchNotices = async () => {
        const res = await noticeActions.retrieveNotice(_id);
       setNotice(res[0]);
    }
    useEffect(() => {
        
        if (!startLoadData) {
            fetchNotices();
        } else {
            setStartLoadData(!startLoadData)
        }
    }, [startLoadData]);
    

    return (<>
        <Helmet>
            <title>{notice?.title}</title>
            <meta property="og:title" content={notice?.title} />
            <meta property="og:description" content={notice?.description} />
            <meta property="og:image" content={notice?.image} />
            <meta property="og:url" content={"https://santafara.org/avviso/"+_id} />
        </Helmet>
        <Grid container 
            paddingLeft={isLargeScreen ? 8 : 3} 
            paddingRight={isLargeScreen ? 8 : 3} 
            paddingTop={4} 
            paddingBottom={4} 
            columnSpacing={5}
            spacing={2} 
            textAlign="left"
        >
            <Grid item xs={12} textAlign={"center"}>
                <Typography variant="h3" fontWeight="bold">{notice?.title}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography  variant="paragraph" color="black">
                    <div dangerouslySetInnerHTML={{ __html: notice?.text }} />    
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <ImageListItem sx={{cursor:'pointer'}}>
                    <img onClick={() => { window.open(notice?.image, '_blank')}} src={notice?.image} alt={notice?.title}/>
                </ImageListItem>
            </Grid>
            <Grid item xs={12} md={1}>
                <Typography variant="paragraph" color="black">{notice?.date}</Typography>
            </Grid>
        </Grid>
    </>);
}

export default NoticeSinglePage;