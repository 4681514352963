import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import useAuthActions from "../services/useAuthActions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { GlobalContext } from "../contexts/GlobalContext";
import { SESSION_OK } from "../util/Constants";

const LoginPage = () => {
    const { setSessionState } = useContext(GlobalContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const authActions = useAuthActions();
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        email: "",
        pwd: ""
    }

    const validationSchema = Yup.object({
        email: Yup.string().required("obbligatorio"),
        pwd: Yup.string().required("obbligatorio")
    })

    const { handleSubmit, control, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    const onSubmit = async (values) => {
        let isAuthenticated = await authActions.login(values);
        setIsError(!isAuthenticated);
        if(isAuthenticated) {
            navigate("/admin");
            setSessionState(SESSION_OK);
            reset();
        }
    }

    return (<>
        <Grid container paddingLeft={isLargeScreen ? 8 : 3} paddingRight={isLargeScreen ? 8 : 3} paddingTop={4} paddingBottom={4} spacing={3}>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
                <Typography variant="h2" fontWeight="bold">Area di amministrazione</Typography>
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
                <Typography variant="h4" fontWeight="bold" textAlign="left">Inserisci le credenziali</Typography>
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6} >
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <Grid container spacing={3}>
                        {isError ? <Grid item xs={12}>
                            <Typography color="error" textAlign="left">Credenziali errate! Riprova</Typography>
                        </Grid> : null}
                        <Grid item xs={12} md={12}>
                            <Controller
                                control={control}
                                name="email"
                                render={({ field, fieldState }) => (
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                control={control}
                                name="pwd"
                                render={({ field, fieldState }) => (
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )} />
                        </Grid>
                        <Grid item xs={12} md={6} />
                        <Grid item xs={12} md={6}>
                            <Button fullWidth variant="contained" type="submit">Accedi</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid item xs={12} md={3} />
        </Grid>
    </>);
}

export default LoginPage;