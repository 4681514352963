import { Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import OrariMesseHeaderImg from "../img/orariMesseHeader.png";
import { getOrariFeriale, getOrariFestivo, Orari } from "../util/Constants";
import BaseNotice from '../components/BaseNotice';

const TimeTablePage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
      
    return (
        <>
            <Paper
                style={{
                minHeight: isLargeScreen ? "500px" : "200px",
                backgroundImage: `url(${OrariMesseHeaderImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                }}
            >
                <Typography variant={isLargeScreen ? "h1" : "h2"} fontWeight="bold" paddingTop={isLargeScreen ? "300px" : "140px"} color="white">
                    ORARI SANTE MESSE
                </Typography>
            </Paper>
            <Grid
                container
                paddingTop={isLargeScreen ? 4 : 0}
                paddingLeft={isLargeScreen ? 8 : 3}
                paddingRight={isLargeScreen ? 8 : 3}
                paddingBottom={4}
                spacing={5}
                textAlign="left"
            >
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={5}>
                    <Typography variant="h2" paddingTop="10px">
                        Feriale (Lun-Sab)
                    </Typography>
                    <Typography variant="h4">
                        {getOrariFeriale()}
                    </Typography>
                    <Typography variant="h2" paddingTop="10px">
                        Festivo (Dom)
                    </Typography>
                    <Typography variant="h4">
                        {getOrariFestivo()}
                    </Typography>
                    <Typography variant="h2" paddingTop="10px">
                        Parroco:
                    </Typography>
                    <Typography variant="h4">
                        {Orari.Parroco}
                    </Typography>
                    <a href="mailto:parroco@santafara.org">
                        <IconButton variant="main">
                            <Typography variant="h4">
                            parroco@santafara.org 
                            </Typography>
                        </IconButton>
                    </a>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BaseNotice />
                </Grid>
            </Grid>
        </>
    );
}

export default TimeTablePage;