import { Grid, ImageListItem, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import NoticeHeaderImg from '../img/noticeHeader.jpg';
import { useEffect, useState } from "react";
import useNoticeActions from "../services/useNoticeActions";
import { useNavigate } from 'react-router-dom';

const NoticePage = () => {
    const [notices, setNotices] = useState([]);
    const [startLoadData, setStartLoadData] = useState(false);
    const noticeActions = useNoticeActions();
    const theme = useTheme();
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const fetchNotices = async () => {
        setNotices(await noticeActions.retrieveNotices());
    }
    useEffect(() => {
        if (!startLoadData) {
            fetchNotices();
        } else {
            setStartLoadData(!startLoadData)
        }
    }, [startLoadData]);
    

    return (<>
        <Paper style={{minHeight: "650px", backgroundImage: (`url(${NoticeHeaderImg})`), backgroundSize: "cover", backgroundPosition: "center" }}>
            <Typography variant="h1" fontWeight="bold" paddingTop="400px" color="white">BACHECA</Typography>
            <Typography variant="h3" color="white" paddingLeft="20px" paddingRight="20px">Tutti gli appuntamenti dei prossimi giorni in basilica</Typography>
        </Paper>
        <Grid container paddingLeft={isLargeScreen ? 8 : 3} paddingRight={isLargeScreen ? 8 : 3} paddingTop={4} paddingBottom={4} spacing={5} textAlign="left">
            {notices?.map((n,i) => <Grid onClick={() => {navigate('/avviso/' + n.id); window.scrollTo(0, 0);}} sx={{cursor:'pointer'}} container item xs={12} md={4} key={i} spacing={2}>
                <Grid item xs={12} >
                    <Typography variant="h3" fontWeight="bold">{n.title}</Typography>
                </Grid>
                <Grid item xs={3} md={0} lg={3}/>
                <Grid item xs={6} md={12} lg={6}>
                    <ImageListItem>
                        <img src={n.image} alt={n.title}/>
                    </ImageListItem>
                </Grid>
                <Grid item xs={3} md={0} lg={3}/>
                <Grid item xs={12}>
                    <Typography variant="paragraph" color="black">{n.description}</Typography>
                </Grid>
            </Grid>)}
        </Grid>
    </>);
}

export default NoticePage;