import { Box, Button, Chip, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { HistoryElements } from "../util/Constants";
import { useRef, useState } from "react";
import HistoryHeaderImg from "../img/historyHeader.jpg";

const HistoryPage = () => {
    const [historyElementIndex,setHistoryElementIndex] = useState(0);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const mobileTitleRef = useRef(null);

    return (<>
        <Paper style={{minHeight: "650px", backgroundImage: (`url(${HistoryHeaderImg})`), backgroundSize: "cover", backgroundPosition: "center" }}>
            <Typography variant="h1" fontWeight="bold" paddingTop="400px" color="white">STORIA</Typography>
            <Typography variant="h3" color="white">Origini della basilica</Typography>
        </Paper>
        {isLargeScreen ? <Grid container paddingLeft={8} paddingRight={8} paddingTop={4} paddingBottom={4} spacing={5}>
            {HistoryElements[historyElementIndex].isVerticalImage ? 
                <Grid item xs={3}>
                    <Paper style={{backgroundImage: (`url(${HistoryElements[historyElementIndex].image})`), backgroundSize: "cover", backgroundPosition: "center", paddingTop: "600px" }}>
                        <Chip label={HistoryElements[historyElementIndex].imageTitle} />
                    </Paper>
                </Grid> 
            : null}
            <Grid item xs={HistoryElements[historyElementIndex].isVerticalImage ? 5 : 8} textAlign={"left"}>
                <Typography variant="h3" fontFamily="MinionPro" fontWeight="bold" textAlign="left" paddingBottom={2}>{HistoryElements[historyElementIndex].title}</Typography>
                {HistoryElements[historyElementIndex].isVerticalImage ? null : 
                    <Paper style={{backgroundImage: (`url(${HistoryElements[historyElementIndex].image})`), backgroundSize: "cover", backgroundPosition: "center", paddingTop: "450px", paddingLeft: "35%", margin: "30px" }}>
                        <Chip label={HistoryElements[historyElementIndex].imageTitle} />
                    </Paper>
                }
                <Typography variant="paragraph" fontFamily="MinionPro" color="black">{HistoryElements[historyElementIndex].text}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Box paddingBottom={8}>
                    <Chip label="STORIA DELLA BASILICA"/>
                </Box>
                {HistoryElements.map((he,i) => i === historyElementIndex ? null :
                    <Box key={i} paddingBottom={8}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Paper style={{backgroundImage: (`url(${he.image})`), backgroundSize: "cover", paddingTop: "100px" }}>
                                    <Chip label={he.imageTitle} />
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h3" fontFamily="MinionPro" fontWeight="bold" padding={1}>{he.title}</Typography>
                                <Button color="secondary" onClick={() => {setHistoryElementIndex(i)}}>Leggi di più</Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Grid>
        </Grid> : <Grid container padding={3}>
            <Grid item xs={12} textAlign={"left"}>
                <Typography variant="h3" fontFamily="MinionPro" fontWeight="bold" textAlign="left" paddingBottom={2} ref={mobileTitleRef}>{HistoryElements[historyElementIndex].title}</Typography>
                <Paper style={{
                    backgroundImage: (`url(${HistoryElements[historyElementIndex].image})`), 
                    backgroundSize: "cover", 
                    backgroundPosition: "center", 
                    paddingTop: HistoryElements[historyElementIndex].isVerticalImage ? "400px" : "150px", 
                    paddingLeft: HistoryElements[historyElementIndex].isVerticalImage ? "15%" : "10%", 
                    margin: "30px" }}
                >
                    <Chip label={HistoryElements[historyElementIndex].imageTitle} />
                </Paper>
                <Typography variant="paragraph" fontFamily="MinionPro" color="black">{HistoryElements[historyElementIndex].text}</Typography>
                <Box paddingTop={4} paddingBottom={4}>
                    <Chip label="STORIA DELLA BASILICA"/>
                </Box>
                {HistoryElements.map((he,i) => i === historyElementIndex ? null :
                    <Box key={i} paddingBottom={8} textAlign="center">
                        <Paper style={{backgroundImage: (`url(${he.image})`), backgroundSize: "cover", paddingTop: "100px" }}>
                            <Chip label={he.imageTitle} />
                        </Paper>
                        <Typography variant="h3" fontFamily="MinionPro" fontWeight="bold" padding={1}>{he.title}</Typography>
                        <Button color="secondary" onClick={() => {setHistoryElementIndex(i); mobileTitleRef.current.scrollIntoView({behavior: 'smooth'}); }}>Leggi di più</Button>
                    </Box>
                )}
            </Grid>
        </Grid>}
    </>);
}

export default HistoryPage;