import './App.css';
import { Grid, ThemeProvider } from '@mui/material';
import { muiTheme } from './util/MuiTheme';
import HomePage from './page/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalContextProvider } from './contexts/GlobalContext';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import TimeTablePage from './page/TimeTablePage';
import HistoryPage from './page/HistoryPage';
import NoticePage from './page/NoticePage';
import NoticeSinglePage from './page/NoticeSinglePage';
import ContactsPage from './page/ContactsPage';
import LoginPage from './page/LoginPage';
import AdminPage from './page/AdminPage';
import AuthNoticePage from './page/AuthNoticePage';
import SessionExpiredModal from './components/SessionExpiredModal';

function App() {

	return (
		<GlobalContextProvider>
			<ThemeProvider theme={muiTheme}>
				<div className="App">
					<BrowserRouter >
						<Grid container>
							<Grid item xs={12}>
								<Header />
							</Grid>
							<Grid item xs={12}>
								<Routes>
									<Route path="/" element={<HomePage />} />
									<Route path="/orari" element={<TimeTablePage />} />
									<Route path="/avvisi" element={<NoticePage />} />
									<Route path="/avviso/:_id" element={<NoticeSinglePage />} />
									<Route path="/storia" element={<HistoryPage />} />
									<Route path="/contatti" element={<ContactsPage />} />
									<Route path="/login" element={<LoginPage/>} />
									<Route path="/admin" element={<AdminPage/>} />
									<Route path="/admin/avviso" element={<AuthNoticePage />} />
									<Route path="/admin/avviso/:_id" element={<AuthNoticePage />} />
								</Routes>
							</Grid>
							<Grid item xs={12}>
								<Footer />
							</Grid>
						</Grid>
						<SessionExpiredModal />
					</BrowserRouter>
					<Loader />
				</div>
			</ThemeProvider>
		</GlobalContextProvider>
	);
}

export default App;
