import { Add, Edit } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useNoticeActions from "../services/useNoticeActions";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [searchInput, setSearchInput] = useState("");
    const [notices, setNotices] = useState([]);
    const [shownNotices, setShownNotices] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const noticeActions = useNoticeActions();
    const navigate = useNavigate();

    const mdColumns = [
        {
            field: "date",
            headerName: "Data",
            flex: 1
        },
        {
            field: "title",
            headerName: "Titolo",
            valueGetter: (params) => { return params.row.title },
            flex: 3
        },
        {
            field: "action",
            headerName: "Modifica",
            renderCell: (params) => { return <IconButton variant="text" onClick={() => { navigate("/admin/avviso/" + params.row.id) }}><Edit /></IconButton> },
            flex: 1
        }
    ];

    const xsColumns = [
        {
            field: "title",
            headerName: "Titolo",
            valueGetter: (params) => { return params.row.title },
            flex: 2
        },
        {
            field: "action",
            headerName: "Modifica",
            renderCell: (params) => { return <IconButton variant="text" onClick={() => { navigate("/admin/avviso/" + params.row.id) }}><Edit /></IconButton> },
            flex: 1
        }
    ];

    const fetchNotices = async () => {
        if (notices?.length > 0) {
            return notices;
        }
        let retrieved = await noticeActions.retrieveNotices(500);
        if(retrieved) {
            setNotices(retrieved);
            setShownNotices(retrieved);
        }
    }

    useEffect(() => {
        if (notices?.length > 0) {
            return notices;
        }
        fetchNotices();
    }, []);

    useEffect(() => {
        setShownNotices(notices.filter(n => n.title.toUpperCase().includes(searchInput.toUpperCase())));
    },[searchInput]);
    
    return (<>
        <Grid container paddingLeft={isLargeScreen ? 8 : 3} paddingRight={isLargeScreen ? 8 : 3} paddingTop={4} paddingBottom={4} spacing={3}>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
                <Typography variant="h2" fontWeight="bold">Area di amministrazione</Typography>
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={6} >
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Cerca un articolo..."
                    value={searchInput}
                    onChange={(e) => { setSearchInput(e.target.value) }}
                />
            </Grid>
            <Grid item xs={12} md={2} >
                <Button fullWidth sx={{ height: '100%' }} onClick={() => { navigate("/admin/avviso") }}>
                    <Add />
                </Button>
            </Grid>
            <Grid item xs={1} md={2} />
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8}>
                <DataGrid
                    columns={isLargeScreen ? mdColumns : xsColumns}
                    rows={shownNotices}
                    getRowId={r => r.id}
                    autoHeight
                    localeText={{ noRowsLabel: 'Nessun Articolo Trovato' }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => { setPageSize(newPageSize) }}
                    rowsPerPageOptions={[5, 10, 20]}
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: "Righe per pagina",
                            labelDisplayedRows: (({ from, to, count, page }) => { return from + "-" + to + " di " + count })
                        }
                    }}
                />
            </Grid>
            <Grid item xs={0} md={2} />
        </Grid>
    </>);
}

export default AdminPage;