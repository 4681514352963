import { useEffect, useState } from "react";
import { Button, Grid, ImageListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useNoticeActions from '../services/useNoticeActions';

const BaseNotice = () => {
    const [notice, setNotice] = useState();
    const noticeActions = useNoticeActions();
    const navigate = useNavigate();
    const [startLoadData, setStartLoadData] = useState(false);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const fetchLastNotice = async () => {
        setNotice((await noticeActions.retrieveNotices(1))[0]);
    }
    useEffect(() => {
        if (!startLoadData) {
            fetchLastNotice();
        } else {
            setStartLoadData(!startLoadData)
        }
    }, [startLoadData]);
    return (
        <>
            <Grid container
                paddingLeft={isLargeScreen ? 8 : 3} 
                paddingRight={isLargeScreen ? 8 : 3} 
                paddingTop={4} 
                paddingBottom={4} 
                spacing={4} 
                textAlign="left"
            >
                <Grid item xs={12} >
                    <Typography variant="h4" fontWeight="bold" paddingBottom={1}>AVVISI IN PRIMO PIANO</Typography>
                </Grid>
                <Grid onClick={() => {navigate('/avviso/' + notice?.id); window.scrollTo(0, 0);}} container sx={{ cursor: 'pointer' }} spacing={2}>
                    <Grid item xs={12} paddingBottom={1}>
                        <Typography variant="h5" fontWeight="bold">{notice?.title}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ImageListItem>
                            <img src={notice?.image} alt={notice?.title} />
                        </ImageListItem>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Typography variant="paragraph" color="black">{notice?.description}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} textAlign={"center"}>
                    <Typography variant="h3" paddingBottom={1}>Vuoi vedere altri eventi?</Typography>
                    <Button onClick={() => {
                        navigate("/avvisi");
                        window.scrollTo(0, 0);
                    }}>
                        <Typography variant="h4" color="white">Vai alla bacheca</Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default BaseNotice;