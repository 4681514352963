import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

const useNoticeActions = () => {
    const {setLoading, axiosInstance, authAxiosInstance} = useContext(GlobalContext);
    
    const retrieveNotices = async (limit) => {
        try{
            setLoading(true);
            let res = await axiosInstance.get('/api/get_articles.php',{params:{limit: limit}});
            setLoading(false);
            return res.data;
        } catch (e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    const retrieveNotice = async (_id) => {
        try{
            setLoading(true);
            let res = await axiosInstance.get('/api/get_articles.php', {params:{id: _id}});
            setLoading(false);
            return res.data;
        } catch (e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    const retrieveImage = async (url) => {
        try {
            setLoading(true);
            let res = await axiosInstance.get(url, { responseType: 'blob' });
            setLoading(false);
            return res.data;
        } catch(e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    const postArticle = async (payload) => {
        try{
            setLoading(true);
            await authAxiosInstance.post("/api/set_articles.php", payload);
            setLoading(false);
            return true;
        } catch(e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    const deleteArticle = async (_id) => {
        try{
            setLoading(true);
            await authAxiosInstance.post("/api/delete_article.php", {id: _id});
            setLoading(false);
            return true;
        } catch(e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    return {
        retrieveNotices,
        retrieveNotice,
        retrieveImage,
        postArticle,
        deleteArticle
    };
}

export default useNoticeActions;