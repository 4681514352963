import { createTheme } from "@mui/material";

export const infoColor = "#FFFFFF";
export const secondaryColor = "#AF8E39";
export const mainColor = "#862709";
export const errorColor = "#FF0000";

export const muiTheme = createTheme({
    typography: {
        fontFamily: [
            'Inter'
        ].join(',')
    },
    palette:{
        primary: {
            main: mainColor
        },
        error: {
            main: errorColor
        },
        info: {
            main: infoColor
        },
        secondary: {
            main: secondaryColor
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                size: 'medium',
                variant: 'contained',
            },
            variants: [
                {
                    props: {variant: 'contained'},
                    style: {
                        color: 'white'
                    }
                },
                {
                    props: {variant: 'text'},
                    style: {
                        color: 'white'
                    }
                }
            ]
        },
        MuiIconButton: {
            variants: [
                {
                    props: {variant: 'filled'},
                    style: {
                        color: 'white',
                        backgroundColor: mainColor,
                        ":hover": {
                            backgroundColor: mainColor,
                            filter: "brightness(80%)"
                        }
                    }
                },
                {
                    props: {variant: 'text'},
                    style: {
                        color: 'white'
                    }
                },
                {
                    props: {variant: 'main'},
                    style: {
                        color: mainColor,
                        fontSize: '500px'
                    }
                }
            ]
        },
        MuiCardActions: {
            defaultProps: {
                style: {
                    justifyContent: 'center'
                }
            }
        },
        MuiTable: {
            defaultProps: {
                bgcolor: secondaryColor
            }
        },
        MuiTableCell: {
            defaultProps: {
                style: {
                    fontSize: '18px',
                    color: mainColor
                }
            }
        },
        MuiDataGrid: {
            defaultProps: {
                style: {
                    fontSize: '18px',
                    backgroundColor: secondaryColor,
                    color: 'white'
                }
            }
        },
        MuiTablePagination: {
            defaultProps: {
                style: {
                    color: 'white'
                }
            }
        },
        MuiAccordion: {
            defaultProps: {
                style: {
                    backgroundColor: secondaryColor
                }
            }
        },
        MuiAccordionDetails: {
            defaultProps: {
                style: {
                    borderTop: "1px solid " + secondaryColor
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                fontFamily: 'Inter',
                fontWeight: 'normal',
                color: mainColor
            },
            variants: [
                {
                    props: {variant: 'title'},
                    style: {
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent"
                    }
                },
                {
                    props: {variant: 'h1'}
                },
                {
                    props: {variant: 'h2'}
                },
                {
                    props: {variant: 'h3'}
                },
                {
                    props: {variant: 'h4'}
                },
                {
                    props: {variant: 'h5'}
                },
                {
                    props: {variant: 'paragraph'}
                }
            ]
        },
        MuiTab: {
            defaultProps: {
                style: {
                    color: "white",
                    fontSize: "20px"
                }
            }
        },
        MuiChip: {
            defaultProps: {
                style: {
                    backgroundColor: mainColor,
                    color: "white",
                    fontSize: "18px",
                    padding: "10px"
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                square: true
            }
            
        }
    }
});

muiTheme.typography.title = {
    fontSize: '32px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '48px',
    },
};

muiTheme.typography.h1 = {
    fontSize: '45px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '70px',
    },
};

muiTheme.typography.h2 = {
    fontSize: '30px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '32px',
    },
};

muiTheme.typography.h3 = {
    fontSize: '24px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
};

muiTheme.typography.h4 = {
    fontSize: '20px'
}

muiTheme.typography.h5 = {
    fontSize: '18px'
}

muiTheme.typography.paragraph = {
    fontSize: '16px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
}