import { ImageListItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import logoSantaFaraImg from "../img/logoSantaFara.png";

const LogoSantaFara = ({logoTitleDistance}) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (<Stack direction={"row"}>
        <ImageListItem>
            <img style={{ height: isLargeScreen ? "90px" : "70px" }} src={logoSantaFaraImg} alt="Logo"/>
        </ImageListItem>
        <Typography variant={isLargeScreen ? "h3" : "h4"} fontFamily="Franciscus" color="white" textAlign="left" paddingLeft={logoTitleDistance}>Basilica<br/> Parrocchia<br/> Santa Fara</Typography>
    </Stack>);
}

export default LogoSantaFara;