import HistorySantaFara from '../img/historySantaFara.png';
import HistoryAnticoIngresso from '../img/historyAnticoIngresso.png';
import HistoryPreziosaReliquia from '../img/historyPreziosaReliquia.png';
import HistoryConvento from '../img/historyConvento.png';

export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const SESSION_TO_INIT = "SESSION_TO_INIT";
export const SESSION_OK = "SESSION_OK";

export const HistoryElements = [
    {
        title: "RITRATTO DI UNA SANTA",
        imageTitle: "SANTA FARA",
        image: HistorySantaFara,
        isVerticalImage: true,
        text: `Fara nacque a Pipimisicum (attuale Poincy) nella diocesi di Meaux, in Austrasia, nell'anno 600 d.C. circa. Il padre Cagnerico era un conte al seguito del re. Un giorno, nell'anno 610, il monaco irlandese Colombano, arrivò come ospite a casa di Cagnerico. La bambina portò al santo delle spighe fuori stagione. Il monaco non stentò a riconoscere il segno. La fanciulla maturò, nel tempo, la sua intenzione di consacrarsi a Dio in monastero.\n Il suo progetto confliggeva con le intenzioni del padre che voleva sposarla ad un giovane di stripe nobile. Iniziò per lei un periodo tormentato. Fuggì di casa ma dei servi ve la riportarono con la forza.Si ammalò, perse la vista e delle febbri ostinate la costrinsero a letto per mesi. Colombano era morto da qualche anno ma l'attività del suo successore, Sant'Eustasio, ridusse Cagnerico a più miti consigli e ne riconobbe egli stesso la santità. Consentì alla giovane di prendere i voti (era il 614) e, le donò un fondo ad Eboriac dove i monaci di Luxeil, guidati dall'abate Eustasio, costruirono il monastero che poi fu posto sotto la guida di Fara, da cui il centro prese il nome, appunto, di Faremoutiers. il monastero adottò inizialmente la regola di San Colombano, poi sostituita da quella di Benedetto. Furono numerosi i miracoli che avvennero a Faremoutiers.\n Molte monache, cresciute con gli insegnamenti di Fara, pervennero alla santità ed operarono miracoli a Faremoutiers. Fara morì il 7 dicembre 658.`
    },
    {
        title: "UBICAZIONE",
        imageTitle: "ANTICO INGRESSO",
        image:  HistoryAnticoIngresso,
        isVerticalImage: 0,
        text: "Il suolo su cui stava per sorgere l'opera apparteneva al benemerito prof. Di Cagno Sessa, sulla via provinciale per Bitritto, ora via Gen. Nicola Bellomo, nelle vicinanze del Rione del Policlinico."
    },
    {
        title: "ORIGINI DELLA RELIQUIA",
        imageTitle: "PREZIOSA RELIQUIA",
        image: HistoryPreziosaReliquia,
        isVerticalImage: 0,
        text: "Ad un anno di distanza, il 7 dicembre 1939, a cura dello stesso prof. Di Cagno Sessa, era già costruita la futura sacrestia della chiesa con alcuni locali annessi. Detta sacrestia che ora funziona da cappella sin al compimento del tempio, fu arricchita di paramenti sacri e di una preziosa reliquia di S. Fara (parte di osso frontale), donata dal monastero di Faremoutiers. La pala dell'altare maggiore rappresenta S. Fara dell'immagine di Sciacca, opera pregevole del prof. Lanave di Bari."
    },
    {
        title: "FONDAZIONE DELLA PARROCCHIA",
        imageTitle: "IL CONVENTO - 1958",
        image: HistoryConvento,
        isVerticalImage: 0,
        text: "Il 7 dicembre del 1943, dato l'incremento della devozione dei fedeli, Mons. Marcello Mimmi, arcivescovo di Bari, accogliendo i voti del prof. Di Cagno Sessa, fondava canonicamente la parrocchia di S. Fara. Il 22 ottobre del 1947, il prof. Di Cagno Sessa con atto del notaio A.S. Vernice, faceva atto di cessione dell'Opera di S. Fara ai Padri Cappuccini della Provincia di Puglia. I Padri Cappuccini, accogliendo i voti dell'insigne Benefattore, hanno dato vita ad una molteplice attività di apostolato e d'intensa operosità di vita e di spirituitalità francescana."
    }
];

export const Orari = {
    FerialeInverno:  "7.30 / 18.30",
    FestivoInverno:  "8.30 / 10.00 / 11.30 / 18.30",
    FerialeEstate:  "7.30 / 19.00",
    FestivoEstate:  "8.30 / 10.00 / 11.30 / 19.00",
    Parroco: "Fra Domenico Donatelli"
};

const isDaylightSavingTimeInItaly = () => {
    const now  = new Date();
    const startDst = new Date(now.getFullYear(), 2, 31); 
    const endDst = new Date(now.getFullYear(), 9, 31);   
    startDst.setDate(startDst.getDate() - startDst.getDay() + (startDst.getDay() > 0 ? 1 : 0));
    endDst.setDate(endDst.getDate() - endDst.getDay() + (startDst.getDay() > 0 ? 1 : 0));
    return now >= startDst && now < endDst;
};

export const getOrariFeriale = (isFooter = 0) => {
    if (isFooter == 1) {
        return isDaylightSavingTimeInItaly() ? Orari.FerialeEstate.replaceAll('/','-') : Orari.FerialeInverno.replaceAll('/','-');
    } else {
        return isDaylightSavingTimeInItaly() ? Orari.FerialeEstate : Orari.FerialeInverno;
    }
};

export const getOrariFestivo = (isFooter = 0) => {
    let split = isDaylightSavingTimeInItaly() ? Orari.FestivoEstate.split(' / ') : Orari.FestivoInverno.split(' / '); 
    if (isFooter == 1) {
        return split[0] + ' - ' + split[1];  
    } else if (isFooter == 2) {
        return split[2] + ' - ' + split[3];  
    } else {
        return isDaylightSavingTimeInItaly() ? Orari.FestivoEstate : Orari.FestivoInverno;        
    }
};