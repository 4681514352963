import { Box, Grid, Stack, IconButton, Tabs, Tab, Typography, useTheme, useMediaQuery, Drawer, Divider } from "@mui/material";
import { secondaryColor } from "../util/MuiTheme";
import LogoSantaFara from "./LogoSantaFara";
import { useLocation, useNavigate } from "react-router-dom/dist";
import FacebookRounded from '@mui/icons-material/FacebookRounded';
import { useEffect, useState } from "react";
import { Menu, YouTube } from "@mui/icons-material";

const Header = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(false);
    const [openSidenav, setOpenSidenav] = useState(false);
    const location = useLocation();

    const handleChange = (event) => {
        setSelectedTab(event.target.id);
        navigate(event.target.id);
    }   

    const linkList = [
        {
            label: "ORARI SANTE MESSE",
            value: "/orari"
        },
        {
            label: "BACHECA",
            value: "/avvisi"
        },
        {
            label: "STORIA",
            value: "/storia"
        },
        {
            label: "CONTATTI",
            value: "/contatti"
        }
    ];

    useEffect(() => {
        if(location.pathname !== '/' && linkList.filter((item) => item.value.includes(location.pathname)).length > 0) {
            setSelectedTab(location.pathname);
        }else{
            setSelectedTab(false);
        }
    },[location]);

    return (
        <Box bgcolor={secondaryColor} paddingLeft={1} paddingRight={1} paddingTop={2} paddingBottom={2}>
            {isLargeScreen ? <Grid container columnSpacing={4}>
                <Grid item xs={2} onClick={() => {navigate("/")}}>
                    <LogoSantaFara logoTitleDistance={0} />
                </Grid>
                <Grid item xs={8}>
                    <Tabs variant="fullWidth" value={selectedTab} onChange={handleChange}>
                        {linkList.map(t => <Tab label={t.label} key={t.value} id={t.value} value={t.value} />)}
                    </Tabs>
                </Grid>
                <Grid item xs={2}>
                    <Box paddingLeft={8}>
                        <Typography color="white" textAlign="left">Seguici su:</Typography>
                        <Stack direction={"row"}>
                            <a href="https://www.facebook.com/SantaFaraBari">
                                <IconButton variant="text"><FacebookRounded/></IconButton>
                            </a>
                            <a href="https://www.youtube.com/@basilicasantafara1958">
                                <IconButton variant="text"><YouTube/></IconButton>
                            </a>
                        </Stack>
                    </Box>
                </Grid>
            </Grid> : <>
                <Grid container>
                    <Grid item xs={7} onClick={() => {navigate("/")}}>
                        <LogoSantaFara />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="paragraph" color="white" textAlign="left">Seguici su:</Typography>
                        <Stack direction={"row"} paddingLeft={3}>
                            <a href="https://www.facebook.com/SantaFaraBari">
                                <IconButton variant="text"><FacebookRounded/></IconButton>
                            </a>
                            <a href="https://www.youtube.com/@basilicasantafara1958">
                                <IconButton variant="text"><YouTube/></IconButton>
                            </a>
                        </Stack>
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <IconButton variant="text" onClick={() => {setOpenSidenav(true)}}><Menu/></IconButton>
                        
                    </Grid>
                </Grid>
                <Drawer
                    variant="temporary"
                    anchor={"top"}
                    open={openSidenav}
                    onClose={() => {setOpenSidenav(false)}}
                    color="white"
                    disableScrollLock
                >
                    <Box bgcolor={secondaryColor} paddingLeft={3} paddingBottom={0}>
                        {linkList.map(t => <div key={t.value}>
                            <Divider variant="fullWidth"/>
                            <Typography 
                                paddingTop={3}
                                paddingBottom={3} 
                                color="white"
                                onClick={() => {
                                    navigate(t.value);
                                    setOpenSidenav(false);
                                }}
                            >
                                {t.label}
                            </Typography>
                        </div>)}
                    </Box>
                </Drawer>
            </>}
        </Box>
    )
}

export default Header;