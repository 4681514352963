import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

const useAuthActions = () => {
    const { setLoading } = useContext(GlobalContext);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BE_BASEURL
    });

    const login = async (data) => {
        try {
            setLoading(true);
            let res = await axiosInstance.post("/api/login.php", data);
            if(res.data.status) {
                localStorage.setItem("Authorization", "Bearer " + res.data.token);
            }
            setLoading(false);
            return res.data.status;
        } catch (e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    };

    return {
        login
    };
}

export default useAuthActions;