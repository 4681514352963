import React from "react";
import { Grid, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ContactsHeaderImg from "../img/contactsHeader.jpeg";
import { FacebookRounded, YouTube, EmailRounded, PhoneRounded } from "@mui/icons-material";
import BaseNotice from "../components/BaseNotice";

const ContactsPage = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Paper
        style={{
          minHeight: "650px",
          backgroundImage: `url(${ContactsHeaderImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h1" fontWeight="bold" paddingTop="400px" color="white">
          CONTATTI
        </Typography>
      </Paper>

      <Grid
        container
        paddingLeft={isLargeScreen ? 8 : 3}
        paddingRight={isLargeScreen ? 8 : 3}
        paddingTop={4}
        paddingBottom={4}
        spacing={5}
        textAlign="left"
      >
        <Grid item xs={12} md={6}>
          <Stack spacing={3} direction={"column"}>
            <a href="mailto:parroco@santafara.org">
              <IconButton variant="main">
                {
                  isLargeScreen ? 
                  <>
                    <EmailRounded sx={{ fontSize: 60 }}/>
                    <Typography sx={{ fontSize: "30px" }}> 
                      parroco@santafara.org 
                    </Typography>
                  </>
                  : 
                  <>
                    <EmailRounded sx={{ fontSize: 40 }}/>
                    <Typography sx={{ fontSize: "20px" }}> 
                      parroco@santafara.org 
                    </Typography>
                  </>
                } 
                  
              </IconButton>
            </a>
            <a href="https://www.facebook.com/SantaFaraBari">
              <IconButton variant="main">
                {
                  isLargeScreen ? 
                  <>
                    <FacebookRounded sx={{ fontSize: 60 }}/>
                    <Typography sx={{ fontSize: "30px" }}>
                      Basilica Santa Fara 
                    </Typography>
                  </>
                  : 
                  <>
                    <FacebookRounded sx={{ fontSize: 40 }}/>
                    <Typography sx={{ fontSize: "20px" }}>
                      Basilica Santa Fara 
                    </Typography>
                  </>
                } 
                
              </IconButton>
            </a>
            <a href="https://www.youtube.com/@basilicasantafara1958">
              <IconButton variant="main">
                {
                  isLargeScreen ? 
                  <>
                    <YouTube sx={{ fontSize: 60 }}/>
                    <Typography sx={{ fontSize: "30px" }}>
                      Basilica Santa Fara 
                    </Typography>
                  </>
                  : 
                  <>
                    <YouTube sx={{ fontSize: 40 }}/>
                    <Typography sx={{ fontSize: "20px" }}>
                      Basilica Santa Fara 
                    </Typography>
                  </>
                } 
                
              </IconButton>
            </a>
            <a href="tel:0805618236">
              <IconButton variant="main">
                {
                  isLargeScreen ? 
                  <>
                    <PhoneRounded sx={{ fontSize: 60 }}/>
                    <Typography sx={{ fontSize: "30px" }}>
                      080 5618236
                    </Typography>
                  </>
                  : 
                  <>
                    <PhoneRounded sx={{ fontSize: 40 }}/>
                    <Typography sx={{ fontSize: "20px" }}>
                      080 5618236
                    </Typography>
                  </>
                } 
                
              </IconButton>
            </a>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseNotice />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactsPage;
