import { Button, Grid, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import HomeHeaderImg from '../img/homeHeader.png';
import { useEffect, useState } from 'react';
import useNoticeActions from '../services/useNoticeActions';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import HomepageCarouselItem from '../components/HomepageCarouselItem';
import HomeAnniversaryHeaderImg from '../img/homeAnniversaryHeader.jpg';

const HomePage = () => {
    const [notice, setNotice] = useState();
    const [noticeSide, setNoticeSide] = useState();
    const noticeActions = useNoticeActions();
    const navigate = useNavigate();
    const [startLoadData, setStartLoadData] = useState(false);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const fetchLastNotice = async () => {
        const res = await noticeActions.retrieveNotices(3);
        setNotice(res[0]);
        setNoticeSide(res?.filter((x) => { return x != res[0] }));
    }
    useEffect(() => {
        if (!startLoadData) {
            fetchLastNotice();
        } else {
            setStartLoadData(!startLoadData)
        }
    }, [startLoadData]);


    return (
        <>
            <Carousel autoPlay indicators={false} interval={3000} duration={2000}>
                {[
                    <HomepageCarouselItem key={"anniversary"} img={HomeAnniversaryHeaderImg} withLogo />,
                    <HomepageCarouselItem key={"standard"} img={HomeHeaderImg} />
                ]}
            </Carousel>
            <Grid
                sx={{ cursor: 'pointer' }}
                container
                paddingLeft={isLargeScreen ? 8 : 3}
                paddingRight={isLargeScreen ? 8 : 3}
                paddingTop={4}
                paddingBottom={4}
                spacing={4}
                textAlign="left"
            >
                <Grid item xs={12} md={8} onClick={() => { navigate('/avviso/' + notice?.id); window.scrollTo(0, 0); }}>
                    <Grid container item spacing={1}>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={10}>
                            <Typography variant="h3" fontWeight="bold">{notice?.title}</Typography>
                        </Grid>
                        <Grid item xs={0} md={1} />
                        <Grid item xs={12} md={4}>
                            <ImageListItem>
                                <img src={notice?.image} alt={notice?.title} />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={0} md={1} />
                        <Grid item xs={12} md={5} paddingBottom={4}>
                            <Typography variant="paragraph" color="black">{notice?.description}</Typography>
                        </Grid>
                        <Grid item xs={0} md={1} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4} spacing={4}>
                    {noticeSide?.map((n, i) => 
                        <Grid 
                            onClick={() => { navigate('/avviso/' + n.id); window.scrollTo(0, 0); }} 
                            sx={{ cursor: 'pointer' }} 
                            container 
                            item
                            key={i}
                            spacing={1}
                        >
                            <Grid item xs={12} paddingBottom={1}>
                                <Typography variant="h3" fontWeight="bold"
                                >{n.title}</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} lg={4}>
                                <ImageListItem>
                                    <img src={n.image} alt={n.title} />
                                </ImageListItem>
                            </Grid>
                            <Grid item xs={6} md={6} lg={5}>
                                <Typography variant="paragraph" color="black">{n.description}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <Typography variant="h3" paddingBottom={1}>Vuoi vedere altri eventi?</Typography>
                    <Button onClick={() => {
                        navigate("/avvisi");
                        window.scrollTo(0, 0);
                    }}>
                        <Typography variant="h4" color="white">Vai alla bacheca</Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default HomePage;