import { createContext, useState } from "react";
import axios from "axios";
import { SESSION_EXPIRED, SESSION_TO_INIT } from "../util/Constants";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [sessionState, setSessionState] = useState(SESSION_TO_INIT);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BE_BASEURL
    });

    const authAxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BE_BASEURL,
        headers: {Authorization: localStorage.getItem("Authorization")}
    });

    authAxiosInstance.interceptors.response.use(res => { return res; }, err => {
        const res = err.response;
        if(res?.status === 401 || res?.status === 403) {
            setOpenSessionExpiredModal(true);
            setSessionState(SESSION_EXPIRED);
        } 
        throw err;
    });

    return <GlobalContext.Provider value={{
        loading,
        setLoading,
        axiosInstance,
        authAxiosInstance,
        setSessionState,
        openSessionExpiredModal,
        setOpenSessionExpiredModal
    }}>
        {children}
    </GlobalContext.Provider>
}